import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/css/bootstrap.min.css';
import '@/assets/css/tiny-slider.css';
import '@/assets/css/style.css';

import '@/assets/js/bootstrap.bundle.min.js';
import '@/assets/js/tiny-slider.js';
import '@/assets/js/custom.js';

import LoadScript from 'vue-plugin-load-script';
import AppView from "./app";


createApp(App)
  .use(router)
  .use(store)
  .use(AppView)
  .use(LoadScript)
  .mount("#app");
