// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "@/assets/css/landing.css";
// import "@/assets/js/plugins/reveal-js/css/reset.css";
// import "@/assets/js/plugins/reveal-js/css/reveal.css";
// import "@/assets/js/plugins/reveal-js/css/theme/white.css";
// import "@/assets/js/plugins/reveal-js/plugin/highlight/monokai.css";
// import "@/assets/js/plugins/reveal-js/plugin/chalkboard/style.css";
// import "@/assets/js/plugins/reveal-js/plugin/customcontrols/style.css";
// import "@/assets/js/plugins/reveal-js/plugin/menu/menu.css";
// import "@/assets/css/reveal-lecture.css";

export default {
    install() {},
};